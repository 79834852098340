// src/components/UserProfile/EditProfile.js
import React, { useEffect, useState } from 'react';
import { getAuth, updateProfile } from 'firebase/auth';
import './UserProfile.css'

const EditProfile = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      setName(user.displayName || '');
      setPhone(user.phoneNumber || '');
    }
  }, [user]);

  const handleProfileUpdate = async () => {
    try {
      await updateProfile(user, {
        displayName: name,
        photoURL: profilePicture ? URL.createObjectURL(profilePicture) : user.photoURL,
      });

      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
    }
  };

  return (
    <div className="edit-profile-container">
      <h2>Edit Profile</h2>
      <form>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label>Phone:</label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div>
          <label>Profile Picture:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <button type="button" onClick={handleProfileUpdate}>Update Profile</button>
      </form>
    </div>
  );
};

export default EditProfile;
