// src/components/Home/Home.js
import React from 'react';
import './Home.css';
import { FaLaptopCode, FaBroom, FaGraduationCap } from 'react-icons/fa';

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Welcome to LM LEGACY HUBS</h1>
          <p>Your Partner in Professional Services and Education</p>
          <button className="cta-button">Explore Our Services</button>
        </div>
      </section>

      {/* Services Highlights */}
      <section className="services-highlights">
        <h2>What We Offer</h2>
        <div className="services-grid">
          <div className="service-card">
            <FaLaptopCode className="service-icon" />
            <h3>IT Solutions</h3>
            <p>Providing tailored software, cloud management, and cybersecurity services.</p>
          </div>
          <div className="service-card">
            <FaBroom className="service-icon" />
            <h3>Cleaning Services</h3>
            <p>Professional cleaning for homes and businesses, from windows to deep cleaning.</p>
          </div>
          <div className="service-card">
            <FaGraduationCap className="service-icon" />
            <h3>Educational Courses</h3>
            <p>Offering courses in healthcare, IT fundamentals, customer service, and more.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <h2>What Our Clients Say</h2>
        <div className="testimonials-grid">
          <div className="testimonial-card">
            <img src="/path/to/testimonial1.jpg" alt="Client 1" />
            <p>"The IT solutions provided by LM LEGACY HUBS helped streamline our business processes."</p>
            <h4>John Doe</h4>
          </div>
          <div className="testimonial-card">
            <img src="/path/to/testimonial2.jpg" alt="Client 2" />
            <p>"Amazing cleaning services! Our office is spotless thanks to their professionalism."</p>
            <h4>Jane Smith</h4>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
