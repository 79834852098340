// src/components/Layout/Header.js
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './Header.css';


const Header = () => {
  const [user, setUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [auth]);

  const handleLogout = async () => {
    await auth.signOut();
    setUser(null);
    navigate('/'); // Redirect to home after logging out
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <header className="header">
      <h1 className="logo">LM LEGACY HUBS</h1>
      <nav>
        <ul className="nav-list">
          <li><Link to="/" className="nav-link">Home</Link></li>
          <li><Link to="/courses" className="nav-link">Courses</Link></li>
          <li><Link to="/services" className="nav-link">Services</Link></li>
          <li><Link to="/contact" className="nav-link">Contact Us</Link></li>
          <li><Link to="/about" className="nav-link">About Us</Link></li>

          {user ? (
            <div className="profile-container" onClick={toggleDropdown}>
              <span className="welcome-text">Welcome, {user.displayName || user.email}</span>
              <img 
                src={user.photoURL || '/default-profile.png'} 
                alt="Profile" 
                className="profile-img" 
              />
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <Link to="/profile">Profile Info</Link> {/* Updated link text */}
                  <Link to="/edit-profile">Edit Profile</Link>
                  <Link to="/booking-history">Booking History</Link>
                  <Link to="/enrolled-courses">Enrolled Courses</Link>
                  <Link to="/account-settings">Account Settings</Link>
                  <button onClick={handleLogout} className="logout-btn">Logout</button>
                </div>
              )}
            </div>
          ) : (
            <>
              <li><Link to="/login" className="nav-link">Login</Link></li>
              <li><Link to="/signup" className="nav-link">Sign Up</Link></li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
