import React, { useState, useEffect } from 'react';
import { getAuth, updateEmail, updatePassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css'; // Import your styles

const AccountSettings = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate(); // For navigation

    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (user) {
            setEmail(user.email); // Set email if user is authenticated
        } else {
            navigate('/login'); // Redirect to login if user is not authenticated
        }
    }, [user, navigate]);

    const handleEmailUpdate = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            await updateEmail(user, email);
            setSuccess('Email updated successfully!');
        } catch (error) {
            setError(error.message);
        }
    };

    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            await updatePassword(user, newPassword);
            setSuccess('Password updated successfully!');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="account-settings-container">
            <h2>Account Settings</h2>
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}

            <form onSubmit={handleEmailUpdate}>
                <h3>Update Email</h3>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter new email"
                    required
                />
                <button type="submit">Update Email</button>
            </form>

            <form onSubmit={handlePasswordUpdate}>
                <h3>Change Password</h3>
                <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password"
                    required
                />
                <button type="submit">Change Password</button>
            </form>
        </div>
    );
};

export default AccountSettings;
