import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Courses.css'; // Import corresponding CSS file
import customerServiceImage from '../assets/course file/Customer Service Excellence.webp';
import healthcareImage from '../assets/course file/healthcarebasic.webp';
import itFundamentalsImage from '../assets/course file/IT Fundamentals.webp';
import SalesandMarketing from '../assets/course file/SalesandMarketing.webp';
import CounselingCareImage from '../assets/course file/CounselingCareImage.webp';
import NetworkingImage from '../assets/course file/Networking.webp';
import Administration from '../assets/course file/Administration.webp';

// Exporting coursesData
export const coursesData = [
  {
    id: 1,
    title: "Healthcare Basics Course",
    duration: "4 Weeks",
    price: "ZAR 3,500",
    image: healthcareImage,
    description: "Learn the essentials of healthcare practices and patient care in this foundational course designed for beginners in the field."
  },
  {
    id: 2,
    title: "Introduction to IT Fundamentals",
    duration: "6 Weeks",
    price: "ZAR 5,500",
    image: itFundamentalsImage,
    description: "Master the basics of IT, including hardware, software, and networking, in this comprehensive introductory course."
  },
  {
    id: 3,
    title: "Customer Service Excellence",
    duration: "3 Weeks",
    price: "ZAR 2,800",
    image: customerServiceImage,
    description: "Learn the principles and practices of exceptional customer service and how to implement them in real-world scenarios."
  },
  {
    id: 4,
    title: "Sales and Marketing Essentials",
    duration: "5 Weeks",
    price: "ZAR 5,000",
    image: SalesandMarketing,
    description: "Get a strong foundation in sales strategies, digital marketing techniques, and customer acquisition."
  },
  {
    id: 5,
    title: "Introduction to Counseling & Wellness",
    duration: "6 Weeks",
    price: "ZAR 6,500",
    image: CounselingCareImage,
    description: "Develop fundamental counseling skills and understand mental health principles to support wellness."
  },
  {
    id: 6,
    title: "Advanced IT and Networking",
    duration: "8 Weeks",
    price: "ZAR 7,500",
    image: NetworkingImage,
    description: "Advance your knowledge in networking, cloud systems, and IT infrastructure management."
  },
  {
    id: 7,
    title: "Basic Business Administration",
    duration: "4 Weeks",
    price: "ZAR 4,000",
    image: Administration,
    description: "Understand core business administration functions, including management, finance, and operations."
  }
];

const Courses = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="courses">
      <h1>Our Courses</h1>
      <p>
        At LM LEGACY HUBS, we are dedicated to empowering individuals with essential skills for today’s dynamic economy. Our short courses are designed to cater to key sectors in South Africa—Healthcare, IT, and Customer Service.
      </p>
      <div className="course-list">
        {coursesData.map((course, index) => (
          <div className="course-card" key={index} onClick={() => navigate(`/courses/${course.id}`)}>
            {course.image && <img src={course.image} alt={course.title} className="course-image" />}
            <h3>{course.title}</h3>
            <p><strong>Duration:</strong> {course.duration}</p>
            <p><strong>Price:</strong> {course.price}</p>
            <button className="enroll-button">Enroll Now!</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;
