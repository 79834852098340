// src/components/EnrolledCourses.js
import React from 'react';

const EnrolledCourses = () => {
  const courses = [
    { id: 1, title: 'Healthcare Basics', status: 'Ongoing' },
    { id: 2, title: 'IT Fundamentals', status: 'Completed' },
  ];

  return (
    <div className="enrolled-courses">
      <h2>Enrolled Courses</h2>
      <ul>
        {courses.map((course) => (
          <li key={course.id}>
            <p>Course: {course.title}</p>
            <p>Status: {course.status}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EnrolledCourses;
