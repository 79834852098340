// src/components/About/About.js
import React from 'react';
import './About.css'; // Import the corresponding CSS file
import { FaLightbulb, FaPeopleArrows, FaHandshake, FaStar } from 'react-icons/fa'; // Import icons for values

const About = () => {
  return (
    <div className="about">
      <h1>About LM LEGACY HUBS</h1>
      <p>
        At LM LEGACY HUBS (PTY) LTD, we are committed to fostering a learning environment 
        that nurtures growth and development. Our mission is to empower individuals with the 
        skills they need to succeed in today's dynamic economy. We offer a blend of 
        educational courses and personal care services to support both personal and professional 
        development.
      </p>
      <h2>Our Vision</h2>
      <p>
        To be a leading provider of skill development and wellness services in South Africa, 
        helping individuals unlock their potential and achieve their dreams.
      </p>
      <h2>Our Values</h2>
      <div className="values-grid">
        <div className="value-card">
          <FaLightbulb className="value-icon" />
          <h3>Empowerment</h3>
          <p>We believe in empowering individuals to reach their full potential.</p>
        </div>
        <div className="value-card">
          <FaPeopleArrows className="value-icon" />
          <h3>Community Support</h3>
          <p>We are dedicated to uplifting our community through various initiatives.</p>
        </div>
        <div className="value-card">
          <FaHandshake className="value-icon" />
          <h3>Integrity</h3>
          <p>We uphold the highest standards of integrity in all our actions.</p>
        </div>
        <div className="value-card">
          <FaStar className="value-icon" />
          <h3>Excellence</h3>
          <p>We strive for excellence in everything we do, ensuring the best outcomes.</p>
        </div>
      </div>
    </div>
  );
};

export default About;
