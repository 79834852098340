// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

import Home from './components/Home';
import Signup from './components/Auth/SignUp';
import Login from './components/Auth/Login';
import Courses from './components/Courses';
import Services from './components/Services';
import Contact from './components/Contact';
import About from './components/About';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import Enroll from './components/Enroll'; // Ensure you import the Enroll component
import CourseDetails from './components/CourseDetails';
import ProfileInfo from './components/UserProfile/ProfileInfo';
import EditProfile from './components/UserProfile/EditProfile';
import BookingHistory from './components/UserProfile/BookingHistory';
import EnrolledCourses from './components/UserProfile/EnrolledCourses';
import AccountSettings from './components/UserProfile/AccountSettings';

const App = () => {
  const [user, setUser] = useState(null);
  const [profilePicture, setProfilePicture] = useState('/default-profile.png');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setProfilePicture(currentUser.photoURL || '/default-profile.png'); // Set initial profile picture
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  return (
    <Router>
      <Header profilePicture={profilePicture} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:id" element={<CourseDetails />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/enroll" element={user ? <Enroll /> : <Navigate to="/login" />} />
        
        {/* Profile Routes */}
        <Route path="/profile" element={<ProfileInfo />} /> {/* Ensure you have this route */}
        <Route path="/edit-profile" element={<EditProfile setProfilePicture={setProfilePicture} />} />
        <Route path="/booking-history" element={<BookingHistory />} />
        <Route path="/enrolled-courses" element={<EnrolledCourses />} />
        <Route path="/account-settings" element={<AccountSettings />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
