import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { coursesData } from './Courses'; // Import the course data correctly
import './CourseDetails.css'; // Import corresponding CSS

const CourseDetails = () => {
  const { id } = useParams(); // Get course ID from URL
  const navigate = useNavigate();
  
  // Find the specific course using the ID
  const course = coursesData.find((course) => course.id === parseInt(id));

  const handleEnroll = () => {
    // Check if the user is logged in (you can modify this logic as needed)
    if (true) { // Replace with actual authentication logic
      navigate('/enroll'); // Redirect to the enrollment form
    } else {
      navigate('/login'); // Redirect to login page if not authenticated
    }
  };

  if (!course) {
    return <div>Course not found</div>; // Display error message if course not found
  }

  return (
    <div className="course-details">
      <div className="course-header">
        <img src={course.image} alt={course.title} className="course-image" />
        <div className="course-info">
          <h1>{course.title}</h1>
          <p><strong>Duration:</strong> {course.duration}</p>
          <p><strong>Price:</strong> {course.price}</p>
          <p>{course.description}</p>
        </div>
      </div>
      <div className="course-content">
        <h2>Course Outline</h2>
        <ul>
          <li>Introduction to the subject</li>
          <li>Core concepts and foundations</li>
          <li>Practical applications and case studies</li>
          <li>Final assessment and certification</li>
        </ul>

        <h2>What You'll Learn</h2>
        <p>
          By the end of this course, you’ll have a strong understanding of the
          basics and practical skills needed to excel in this field.
        </p>
        
        <h2>Requirements</h2>
        <ul>
          <li>Basic understanding of the topic (optional)</li>
          <li>Access to a computer or mobile device</li>
          <li>Motivation to learn and apply new skills</li>
        </ul>
      </div>

      <p>
          <strong>All courses will be offered online via teams and is self paced only courses spesified otherwise shall be in person</strong>
        </p>

      <button onClick={handleEnroll} className="enroll-button">Enroll Now</button>
    </div>
  );
};

export default CourseDetails;
