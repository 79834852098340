// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; 
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyArnuw9YkjJ-ekjd8t3sBrnOx1eTVjl0tU",
  authDomain: "lmlegacy-9a6d9.firebaseapp.com",
  projectId: "lmlegacy-9a6d9",
  storageBucket: "lmlegacy-9a6d9.appspot.com",
  messagingSenderId: "662648199606",
  appId: "1:662648199606:web:6e8d4b17764a06d6e6c917",
  measurementId: "G-WEFY0VZYSM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };