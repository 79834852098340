import React, { useState } from 'react';
import './Enroll.css';
import Swal from 'sweetalert2'; // For pop-up notifications

const Enroll = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    idNumber: '',
    email: '',
    phone: '',
    selectedCourse: '',
  });

  const courses = [
    'Healthcare Basics',
    'IT Fundamentals',
    'Customer Service Excellence',
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const response = await fetch(e.target.action, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    });

    if (response.ok) {
      // Show success notification using SweetAlert
      Swal.fire({
        icon: 'success',
        title: 'Enrollment Successful!',
        text: 'Your enrollment has been submitted successfully. Please send proof of payment via email or WhatsApp.',
      });

      e.target.reset(); // Reset form fields
      setFormData({
        firstName: '',
        lastName: '',
        idNumber: '',
        email: '',
        phone: '',
        selectedCourse: '',
      });
    } else {
      // Show error notification for submission failure
      Swal.fire({
        icon: 'error',
        title: 'Submission Error',
        text: 'There was a problem submitting your request. Please try again later.',
      });
    }
  };

  return (
    <div className="enroll-container">
      <h2>Enroll in a Course</h2>
      <form action="https://formspree.io/f/mldergvg" method="POST" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input 
            type="text" 
            name="firstName" 
            value={formData.firstName} 
            onChange={handleChange} 
            required 
            placeholder="Enter your first name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input 
            type="text" 
            name="lastName" 
            value={formData.lastName} 
            onChange={handleChange} 
            required 
            placeholder="Enter your last name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="idNumber">ID Number</label>
          <input 
            type="text" 
            name="idNumber" 
            value={formData.idNumber} 
            onChange={handleChange} 
            required 
            maxLength={13} 
            placeholder="13-digit ID number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
            placeholder="Enter your email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input 
            type="text" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange} 
            required 
            placeholder="Enter your phone number"
          />
        </div>
        <div className="form-group">
          <label htmlFor="selectedCourse">Select Course</label>
          <select 
            name="selectedCourse" 
            value={formData.selectedCourse} 
            onChange={handleChange} 
            required
          >
            <option value="">-- Select a Course --</option>
            {courses.map((course, index) => (
              <option key={index} value={course}>{course}</option>
            ))}
          </select>
        </div>

        <p className="payment-info">
          <strong>Important:</strong> After submitting this form, please send proof of payment to 
          <strong> support@lmlegacyhubs.com</strong> or WhatsApp it to <strong>+27 61 339 3647</strong>   with your full name and ID number.
        </p>

        <p className="banking-details">
          <strong>Banking Details:</strong><br />
          Bank: Capitec Business<br />
          Account Number: 1052635008<br />
          Account Holder: LM Legacy Hubs<br />
          Branch Code: 470010
        </p>

        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default Enroll;
