// src/components/Contact/Contact.js
import React from 'react';
import './Contact.css'; // Import the CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Added faWhatsapp
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Correct import for faEnvelope

const Contact = () => {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p>We would love to hear from you! Feel free to reach out through any of the methods below.</p>

      <div className="contact-info">
        <h2>Get in Touch</h2>
        <form className="contact-form">
          <div className="form-group">
            <label>Your Name:</label>
            <input type="text" required />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" required />
          </div>
          <div className="form-group">
            <label>Message:</label>
            <textarea rows="4" required />
          </div>
          <button type="submit" className="submit-btn">Send Message</button>
        </form>
      </div>

      <div className="social-links">
        <h2>Follow Us</h2>
        <div className="icons">
          <a href="https://www.facebook.com/profile.php?id=61565793071800" target="_blank" rel="noopener noreferrer" className="icon">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="icon">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.linkedin.com/company/lm-legacy-hubs-pty-ltd/" target="_blank" rel="noopener noreferrer" className="icon">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://wa.me/27613393647" target="_blank" rel="noopener noreferrer" className="icon">
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          <a href="mailto:lmlegacyhubs@gmail.com" className="icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
