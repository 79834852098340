import React, { useState } from 'react';
import { FaCloud, FaCog, FaSprayCan, FaBriefcase, FaHeartbeat } from 'react-icons/fa';
import './Services.css';

const servicesData = {
  IT: {
    title: "IT Services",
    description: "Comprehensive IT solutions to elevate your business.",
    icon: <FaCloud />,
    serviceTypes: ["Network Setup", "IT Support", "Cloud Services"],
  },
  Consulting: {
    title: "Consulting Services",
    description: "Expert advice for your business strategy and growth.",
    icon: <FaCog />,
    serviceTypes: ["Business Strategy", "Market Research", "Financial Planning"],
  },
  Cleaning: {
    title: "Cleaning Services",
    description: "Professional cleaning for your home or office.",
    icon: <FaSprayCan />,
    serviceTypes: ["Home Cleaning", "Office Cleaning", "Carpet Cleaning"],
  },
  Beauty: {
    title: "Beauty Services",
    description: "Revitalize with our beauty and wellness services.",
    icon: <FaHeartbeat />,
    serviceTypes: ["Facial", "Massage", "Hair Treatment"],
  },
  Business: {
    title: "Business Services",
    description: "Tailored solutions for your business needs.",
    icon: <FaBriefcase />,
    serviceTypes: ["Accounting", "Legal Consultation", "HR Services"],
  },
};

const Services = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [selectedServiceType, setSelectedServiceType] = useState('');

  const handleBookingClick = (service) => {
    setSelectedService(service);
    setFormVisible(true);
  };

  const handleCloseModal = () => {
    setFormVisible(false);
    setSubmissionSuccess(false);
    setSelectedServiceType('');
  };

  const handleServiceTypeChange = (e) => {
    setSelectedServiceType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch("https://formspree.io/f/xyzygkbj", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmissionSuccess(true);
      } else {
        console.error("Form submission error:", response);
      }
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  return (
    <div className="services-container">
      {/* Hero Section */}
      <header className="hero-section">
        <h1>Our Services</h1>
        <p>Your one-stop solution for IT, consulting, cleaning, and beauty services!</p>
      </header>

      {/* Service Icons */}
      <div className="service-icons">
        {Object.entries(servicesData).map(([key, { title, description, icon }]) => (
          <div 
            key={key} 
            className="service-icon" 
            onClick={() => handleBookingClick(servicesData[key])}
          >
            <div className="icon">{icon}</div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        ))}
      </div>

      {/* Modal for Booking Form */}
      {formVisible && (
        <div className="modal">
          <div className="booking-form-popup">
            <h3>Booking Request for {selectedService.title}</h3>
            <button className="cancel-btn" onClick={handleCloseModal}>Close</button>
            {submissionSuccess ? (
              <p>Your booking request has been submitted successfully!</p>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Your Name:</label>
                  <input type="text" name="name" required />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input type="email" name="email" required />
                </div>
                <div className="form-group">
                  <label>Phone Number:</label>
                  <input type="tel" name="phone" required />
                </div>
                <div className="form-group">
                  <label>Preferred Date:</label>
                  <input type="date" name="date" required />
                </div>
                <div className="form-group">
                  <label>Select Service Type:</label>
                  <select name="serviceType" value={selectedServiceType} onChange={handleServiceTypeChange} required>
                    <option value="">Select a service type</option>
                    {selectedService.serviceTypes && selectedService.serviceTypes.map((serviceType, index) => (
                      <option key={index} value={serviceType}>{serviceType}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Additional Information:</label>
                  <textarea name="message" rows="4" />
                </div>
                <button className="submit-btn" type="submit">Submit Request</button>
              </form>
            )}
          </div>
        </div>
      )}

      {/* Testimonials Section */}
      <div className="testimonials">
        <h2>What Our Clients Say</h2>
        <blockquote>
          "Amazing service! The team was professional and efficient."
          <cite>— Jane Doe</cite>
        </blockquote>
        <blockquote>
          "Their IT solutions transformed our business!"
          <cite>— John Smith</cite>
        </blockquote>
      </div>
    </div>
  );
};

export default Services;
