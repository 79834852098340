// src/components/UserProfile/ProfileInfo.js
import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import './UserProfile.css';

const ProfileInfo = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    phone: '',
    profilePicture: '/default-profile.png',
  });

  const auth = getAuth();

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setProfileData({
        name: currentUser.displayName || 'Not provided',
        email: currentUser.email || 'Not provided',
        phone: currentUser.phoneNumber || 'Not provided',
        profilePicture: currentUser.photoURL || '/default-profile.png',
      });
    }
  }, [auth]);

  return (
    <div className="profile-info-container">
      <h2>Profile Information</h2>
      <img
        src={profileData.profilePicture}
        alt="Profile"
        className="profile-picture"
      />
      <p><strong>Name:</strong> {profileData.name}</p>
      <p><strong>Email:</strong> {profileData.email}</p>
      <p><strong>Phone:</strong> {profileData.phone}</p>
    </div>
  );
};

export default ProfileInfo;
